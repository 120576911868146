interface Category {
  id: number;
  name: string;
  slug: string;
}
export default class ProductObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data;
  }

  containsEggs = () => {
    const meta = this.#metaItem("contains_eggs");
    return meta ? meta.value === "1" : false;  
  }

  containsFish = () => {
    const meta = this.#metaItem("fish");
    return meta ? meta.value === "1" : false;  
  }

  containsShellfish = () => {
    const meta = this.#metaItem("Shellfish");
    return meta ? meta.value === "1" : false;  
  }

  containsSesame = () => {
    const meta = this.#metaItem("contains_sesame");
    return meta ? meta.value === "1" : false;  
  }

  getCalories = () => {
    const meta = this.#metaItem("cal");
    return meta ? meta.value : "";  
  }

  getMostPopular = () => {
    return this.data.order_count;
  }

  getCarbs = () => {
    const meta = this.#metaItem("carbs");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;
  }
  
  getCategoryIds = () => {
    return this.data.categories.map((category: Category ) => {
      return category.id;
    });
  }

  getFat = () => {
    const meta = this.#metaItem("fat");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionFat = () => {
    const meta = this.#metaItem("fat");
    return meta ? meta.value : "";
  }

  getServingsPerContainer = () => {
    const meta = this.#metaItem("servings_per_container");
    return meta ? meta.value : "";
  }

  getServingsPerSize = () => {
    const meta = this.#metaItem("serving_size");
    return meta ? meta.value : "";
  }

  getSaturatedFat = () => {
    const meta = this.#metaItem("saturated_fat");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionSaturatedFat = () => {
    const meta = this.#metaItem("saturated_fat");
    return meta ? meta.value : "";
  }

  getCarbohydrate = () => {
    const meta = this.#metaItem("carbohydrate");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionCarbohydrate = () => {
    const meta = this.#metaItem("carbohydrate");
    return meta ? meta.value : "";
  }

  getSugar = () => {
    const meta = this.#metaItem("sugar");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionSugar = () => {
    const meta = this.#metaItem("sugar");
    return meta ? meta.value : "";
  }

  getDietaryFiber = () => {
    const meta = this.#metaItem("dietary_fiber");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionDietaryFiber = () => {
    const meta = this.#metaItem("dietary_fiber");
    return meta ? meta.value : "";
  }

  getCholesterol = () => {
    const meta = this.#metaItem("cholesterol");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionCholesterol = () => {
    const meta = this.#metaItem("cholesterol");
    return meta ? meta.value : "";
  }

  getSodium = () => {
    const meta = this.#metaItem("sodium");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;  
  }

  getNutritionSodium = () => {
    const meta = this.#metaItem("sodium");
    return meta ? meta.value : "";
  }

  getIngredients = () => {
    const meta = this.#metaItem("ingredients");
    return meta ? meta.value : "";
  }

  getSubIngredients = () => {
    const meta = this.#metaItem("sub_ingredients");
    return meta ? meta.value : "";
  }
  
  getNutrLabelID = () => {
    const meta = this.#metaItem("nutrition_label");
    return meta ? meta.value : "";
  }
  
  getPoints = () => {
    const meta = this.#metaItem("_wc_points");
    return meta ? Math.round(meta.value) : 0;  
  }

  getEarnedPoints = () => {
    const meta = this.#metaItem("_wc_points_modifier");
    return meta ? meta.value : 0;  
  }
  
  getProtein = () => {
    const meta = this.#metaItem("protein");
    if (meta) {
      const val = parseInt(meta.value);
      if (isNaN(val)) return 0;
      return val;
    }
    return 0;
  }

  getNutritionProtein = () => {
    const meta = this.#metaItem("protein");
    return meta ? meta.value : "";
  }
  
  hasCategory = (categories: string | Array<string>) => {
    categories = Array.isArray(categories) ? categories : [categories];
    
    for (const category of categories) {
      if (
        this.data.categories.some((product_cat: { slug: string }) => {
          return product_cat.slug === category;
        })
      ) {
        return true;
      }
    }
    return false;
  }
  
  hasCategoryID = (categoryIds: number | Array<number>) => {
    categoryIds = Array.isArray(categoryIds) ? categoryIds : [categoryIds];

    for (const categoryId of categoryIds) {
      if (
        this.data.categories.some((productCat: { id: number }) => {
          return productCat.id === categoryId;
        })
      ) {
        return true;
      }
    }
    return false;
  }

  hasMacros = () => {
    return this.getCalories() || this.getCarbs() ||
      this.getFat() || this.getNutritionFat() || this.getProtein() || this.getNutritionProtein();
  }
  
  hasTag = (tag: string) => {
    return this.data.tags.some((product_tag: { slug: string }) => {
      return product_tag.slug === tag;
    });
  }
  
  isBreakfastItem = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("Breakfast") : false;
  }

  isEmp180Approved = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("EMP180 Approved") : false;
  }

  isLowCarb = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("Low Carb &lt; 25") : false;
  }

  isLowSodium = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("Low Sodium") : false;
  }

  isNameYourPrice = () => {
    return this.data.meta_data.some((meta_item: { key: string, value: string }) => {
      return meta_item.key === "_nyp" && (meta_item.value === "yes" || this.data.slug.startsWith('mighty-bucks-gift-card'));
    });
  }

  isNew = () => {
    if ('new_product' in this.data  && this.data.new_product === true) {
      return true;
    };

    return false;
  }

  isOnSale = () => {
    if ('on_sale' in this.data  && this.data.on_sale === true) {
      return true;
    };

    return false;
  }

  isOver500Cal = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("Over 500 Calories") : false;
  }

  isPaleoFriendly = () => {
    const meta = this.#metaItem("paleo_friendly");
    return meta ? meta.value === "1" : false;  
  }

  isProteinBoosted = () => {
    const meta = this.#metaItem("protein_boosted");
    return meta ? meta.value === "1" : false;  
  }

  isSpicy = () => {
    const meta = this.#metaItem("spicy");
    return meta ? meta.value === "1" : false;  
  }

  isUnder500Cal = () => {
    const diet = this.#getDietAttribute();
    return diet ? diet.options.includes("Under 500 Calories") : false;
  }
  
  isVegetarian = () => {
    const meta = this.#metaItem("vegetarian");
    return meta ? meta.value === "1" : false;  
  }
  

  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------
  
  #getDietAttribute = () => {
    return this.data.attributes.find((attribute: { name: string }) => {
      return attribute.name === "Diet";
    });
  }
  
  
  #metaItem = (key: string) => {
    return this.data.meta_data.find((meta_item: { key: string })  => {
      return meta_item.key === key;
    });
  }


  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static getById = (products: Array<Record<string, any>>, id: number)  => {
    const product =  products.find((product) => {
      return product.id === id;
    });

    if (product) return new ProductObj(product);

    return null;
  }  

  static getBySlug = (products: Array<Record<string, any>>, slug: string)  => {
    const product =  products.find((product) => {
      return product.slug === slug;
    });

    if (product) return new ProductObj(product);

    return null;
  }  
}