import type { RootState } from '../../app/store';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ShippingAPI from '../../API/shippingAPI';

export const loadShippingMethods = createAsyncThunk(
  'shipping/loadMethods',
  async () => {
    return await ShippingAPI.getMethods();
  }
);

interface ShippingState {
  methods: Array<Record<string, any>>;
  loaded: boolean;
  loading: boolean;
  loadError: boolean;
}

const initialState: ShippingState = {
  methods: [],
  loaded: false,
  loading: false,
  loadError: false
}

export const shippingSlice = createSlice({
  name: 'shipping',
  initialState: initialState,
  reducers: {
    resetShippingMethods: (state, action: PayloadAction<undefined>) => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadShippingMethods.pending, (state, action) => {
      state.loadError = false;
      state.loading = true;
    });
    builder.addCase(loadShippingMethods.fulfilled, (state, action) => {
      return {
        ...state,
        methods: [...action.payload],
        loading: false,
        loaded: true
      }
    });
    builder.addCase(loadShippingMethods.rejected, (state) => {
      state.loadError = true;
      state.loading = false;
    });

  }
});

export const { resetShippingMethods } = shippingSlice.actions;
export const selectShippingMethods = (state: RootState) => state.shipping.methods;
export const selectShippingLoaded = (state: RootState) => state.shipping.loaded;
export const selectShippingLoading = (state: RootState) => state.shipping.loading;
export const selectShippingLoadError = (state: RootState) => state.shipping.loadError;
export default shippingSlice.reducer;