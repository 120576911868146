import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface CheckoutState {
  pendingOrder: Record<string, any> | null;
  applePayPayment: Record<string, any> | null;
  googlePayPayment: Record<string, any> | null;
}

const initialState: CheckoutState = {
  pendingOrder: {},
  applePayPayment: {},
  googlePayPayment: {}
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialState,
  reducers: {
    resetCheckout: (state) => {
      return initialState;
    },
    setPendingOrder: (state, action) => {
      return {
        ...state,
        pendingOrder: { ...action.payload }
      }
    },
    setApplePayPayment: (state, action) => {
      return {
        ...state,
        applePayPayment: action.payload
      }
    },
    setGooglePayPayment: (state, action) => {
      return {
        ...state,
        googlePayPayment: action.payload
      }
    },
  }
});

export const { resetCheckout, setPendingOrder, setApplePayPayment, setGooglePayPayment } = checkoutSlice.actions;
export const selectPendingOrder = (state: RootState) => state.checkout.pendingOrder;
export const selectApplePayPayment = (state: RootState) => state.checkout.applePayPayment;
export const selectGooglePayPayment = (state: RootState) => state.checkout.googlePayPayment;
export default checkoutSlice.reducer;