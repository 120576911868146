import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from '../../../app/store';
import pagesAPI from "../../../API/pagesAPI";

interface MightydealState {
    mightydeals: Array<Record<string, any>>;
    loaded: boolean;
    loading: boolean;
    loadError: boolean;
}

const initialState: MightydealState = {
    mightydeals: [],
    loaded: false,
    loading: false,
    loadError: false,
}

export const loadMightyDeals = createAsyncThunk(
    'mightydeals/loadMightyDeals',
    async () => {
        let response = await pagesAPI.getMightyDeals();
        return response
    }
);

export const mightydealSlice = createSlice({
    name: "mightydeals",
    initialState: initialState,
    reducers: {
        addMightyDeals: (state, action: PayloadAction<Record<string, any>>) => {
            return {
                ...state,
                mightydeals: [...state.mightydeals, action.payload]
            }
        },
        resetMightyDeals: (state, action: PayloadAction<undefined>) => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadMightyDeals.pending, (state) => {
            return {
                ...state,
                loading: true
            }
        });
        builder.addCase(loadMightyDeals.fulfilled, (state, action) => {
            return {
                ...state,
                mightydeals: { ...action.payload },
                loading: false,
                loaded: true
            }
        });
        builder.addCase(loadMightyDeals.rejected, (state) => {
            return {
                ...state,
                loading: false,
                loadError: true
            }
        });
    }
});

export const { addMightyDeals, resetMightyDeals } = mightydealSlice.actions;
export const selectMightyDeals = (state: RootState) => state.mightydeals.mightydeals;
export const selectMightyDealsLoaded = (state: RootState) => state.mightydeals.loaded;
export const selectMightyDealsLoadError = (state: RootState) => state.mightydeals.loadError;
export const selectMightyDealsLoading = (state: RootState) => state.mightydeals.loading;
export default mightydealSlice.reducer;