import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session';
import referralsReducer from "../features/referrals/referralsSlice";
import cartReducer from "../features/cart/cartSlice";
import checkoutReducer from "../features/checkout/checkoutSlice";
import couponsReducer from "../features/coupons/couponsSlice";
import customerReducer from "../features/customer/customerSlice";
import deliveryReducer from "../features/delivery/deliverySlice";
import guestReducer from "../features/guest/guestSlice";
import mealPlansReducer from "../features/meal_plans/mealPlansSlice";
import mobileReducer from "../features/mobile/mobileSlice";
import ordersReducer from "../features/orders/ordersSlice";
import productsReducer from "../features/products/productsSlice";
import shippingReducer from "../features/shipping/shippingSlice";
import userReducer from "../features/user/userSlice";
import dietaryPlansReducer from "../features/dietary_plans/dietarySlice";
import customerDetailsReducer from "../features/customer/customerDetailSlice";
import editAccountOptionsReducer from "../features/customer/editaccountOptionsSlice";
import mightyFitReducer from "../pages/MightyFit/core/filterSlice";
import workoutReducer from "../pages/MightyFit/core/workoutSlice";
import workoutDetailsReducer from "../pages/MightyFit/core/detailsSlice";
import workoutPlansReducer from "../pages/MightyFit/core/workoutPlansSlice";
import autoShipReducer from "../pages/AutoshipPages/core/autoShipSlice";
import orderPlannerReducer from "../pages/AutoshipPages/core/orderPlannerOrdersSlice";
import mightyDealReducer from "../pages/MightyDealsPages/core/mightydealSlice";

const persistConfig = {
  key: 'root',
  storage: sessionStorage
}

export const appReducer = combineReducers({
  cart: cartReducer,
  checkout: checkoutReducer,
  coupons: couponsReducer,
  customer: customerReducer,
  delivery: deliveryReducer,
  guest: guestReducer,
  mealPlans: mealPlansReducer,
  mobile: mobileReducer,
  orders: ordersReducer,
  products: productsReducer,
  referrals: referralsReducer,
  shipping: shippingReducer,
  user: userReducer,
  dietaryPlans: dietaryPlansReducer,
  customerDetails: customerDetailsReducer,
  editAccountOptions: editAccountOptionsReducer,
  mightyFit: mightyFitReducer,
  workouts: workoutReducer,
  details: workoutDetailsReducer,
  workoutPlans: workoutPlansReducer,
  autoship: autoShipReducer,
  op_orders: orderPlannerReducer,
  mightydeals: mightyDealReducer,
});


const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer: any = (state: any, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    return persistedReducer(undefined, action);
  }
  return persistedReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;