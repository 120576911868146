export default class OrdersAPI {
  static async getOrders(token: string, page: number = 1, per_page: number = 5) {
    let response: any = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/orders?token=' + token + '&page=' +
      page + '&per_page=' + per_page,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while retrieving orders. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let result = {
      total_pages: parseInt(response.headers.get('x-wp-totalpages')),
      json: await response.json()
    }
    return result;
  }

  static async getOrdersList(userId: number) {
    let response: any = await (fetch(
      process.env.REACT_APP_API_URL + 'wp-json/mm/v1/user-orders/' + userId,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while retrieving orders. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    let result = {
      total_pages: parseInt(response.headers.get('x-wp-totalpages')),
      json: await response.json()
    }
    return result;
  }

  static async getOrdersById(ordersId: number) {
    const consumerKey = process.env.REACT_APP_WC_API_CONSUMER_KEY;
    const consumerSecret = process.env.REACT_APP_WC_API_CONSUMER_SECRET;

    if (!consumerKey || !consumerSecret) {
      throw new Error('Consumer key or secret not found in environment variables');
    }

    const url = `${process.env.REACT_APP_API_URL}wp-json/wc/v3/orders/${ordersId}`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`)
    };

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        console.error('HTTP error while retrieving orders. Status:', response.status);
        throw new Error(`HTTP error. status: ${response.status}`);
      }

      const totalPages = parseInt(response.headers.get('x-wp-totalpages') || '0', 10);
      const json = await response.json();

      return {
        total_pages: totalPages,
        json: json,
      };
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  static async createOrder(token: string, order: Object) {
    let response: any = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/orders',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, order: order })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating order. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async refundOrder(
    token: string,
    orderId: number,
    transId: string
  ) {
    let response: any = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/orders/' + orderId + "/refund",
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, auth_net_trans_id: transId })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async updateOrder(token: string, order_id: number,
    order: Object) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/orders/' + order_id,
      {
        method: 'PUT',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, order: order })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async createOrderNote(
    token: string,
    order_id: number,
    note: string
  ) {
    let response: any = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/orders/' + order_id + "/notes",
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ token: token, note: note })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async checkOrderQTY(token: string, cartItems: any) {
    const url = process.env.REACT_APP_API_URL + 'wp-json/mmr/v1/check_product_stock';
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);

    const requestBody = {
      "products": cartItems[0]
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        console.error('HTTP error:', response);
        throw new Error(`HTTP error. status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  static async checkOrderTax() {
    const url = process.env.REACT_APP_API_URL + 'wp-json/wc/v3/taxes?per_page=100';
    const headers = new Headers();
    headers.append('content-type', 'application/json');
    headers.append('Accept', 'application/json');

    const credentialsStaging = 'ck_28ad9686cda5482e7d01b453d9dd0ac3781ee60e:cs_66c82fe3151d9ade470bb7b5fa40ea2f08962cc8';
    const credentialsLive = 'ck_0f4c49c66c620ff62fd97489eb717cb4e3909dde:cs_2126e2d51e9ffbac66779cc96d6a429de2e82fef';
    const credentials = process.env.REACT_APP_API_URL === 'https://emmstaging.com/' ? credentialsStaging : credentialsLive;
    const base64Credentials = btoa(credentials);

    headers.append('Authorization', 'Basic ' + base64Credentials);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        console.error('HTTP error:', response);
        throw new Error(`HTTP error. status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  }

  static async googleAddressverification(address: Object, apiKey: any) {
    let response: any = await (fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${apiKey}`,
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ address })
      }
    ));
    if (!response.ok) {
      console.error('HTTP error while creating order. Status:', response.status);
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }
}