const metaData: Record<string, { title: string; description: string; image: string }> = {
    "/": {
        title: "MightyMeals - Chef-Prepared Healthy Meals Delivered Fresh To You!",
        description: "Best rated fully cooked, ready-to-eat Meal Prep Delivery Service in DC, NYC, Philadelphia, New Jersey, Virginia, Maryland, Pennsylvania, North Carolina & Delaware!",
        image: "https://eatmightymeals.com/wp-content/uploads/2019/08/DSC6681.jpg",
    },
    "/nutrition": {
        title: "MightyMeals - Free Nutritional Advice",
        description: "Get free nutritional advice from our Registered Dietitian today and make the most of the Nation's Fastest Growing fully cooked, ready-to-eat Meal Prep Delivery Service!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/11/nutritional_image1.jpg",
    },
    "/order": {
        title: "Order Healthy Chef-Prepared Meals Online | MightyMeals Menu",
        description: "Browse the MightyMeals menu and order fresh, chef-prepared meals delivered to your door. Customize your selections with high-protein, low-carb, vegetarian, and more options!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2019/07/Image-5.jpg",
    },
    "/mightydeals": {
        title: "Buy MightyMeals Gift Cards & MightyBucks | Save Big on Healthy Meal Prep Delivery",
        description: "Get MightyMeals gift cards or MightyBucks for fresh, chef-prepared meal delivery. Save up to 10% on high-protein, low-carb, and diet-friendly meals delivered straight to your door!",
        image: "https://eatmightymeals.com/wp-content/uploads/2012/05/gift_card.png",
    },
    "/reviews": {
        title: "MightyMeals Reviews | Best Meal Prep Service Rated by Customers",
        description: "See why MightyMeals is the top-rated meal prep delivery service! Read real reviews from satisfied customers about our fresh, healthy, chef-prepared meals.",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/12/OD0A2279-768x512.jpg",
    },
    "/how-it-works": {
        title: "How MightyMeals Works | Fresh Meal Prep Delivery Made Easy",
        description: "Learn how MightyMeals delivers fresh, chef-prepared meals to your door. Explore over 150 healthy meal options for breakfast, lunch, dinner, and snacks!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2022/09/Mighty-Meals-low-1.gif",
    },
    "/catering": {
        title: "Catering Services by MightyMeals | Healthy Meal Prep for Events",
        description: "Discover MightyMeals catering for fresh, chef-prepared meals at your events. Customized menus, white-glove service, and delivery for corporate offices, universities, sports teams, and more!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2024/06/DSC04574.jpg",
    },
    "/our-mission": {
        title: "About MightyMeals | Fresh, Locally-Sourced Meal Prep Delivery",
        description: "Learn about MightyMeals, your trusted source for fresh, chef-prepared, locally-sourced meals. Discover our mission, meet the team, and see how we give back to the community.",
        image: "https://blog.mightymeals.com/wp-content/uploads/2024/01/Founders-1-1.jpg",
    },
    "/sustainability": {
        title: "Sustainability at MightyMeals | Eco-Friendly Meal Prep Delivery",
        description: "Explore MightyMeals' commitment to sustainability with eco-friendly packaging, lower carbon footprint, recyclable materials, and reusable gel packs. Healthy meals with environmental responsibility!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/10/Sleeves-1536x1026.jpg",
    },
    "/enterprise-partnerships": {
        title: "Join the MightyMeals Enterprise Partnership Program | Promote Healthy Meals",
        description: "Partner with MightyMeals and join 250+ businesses promoting healthy, chef-prepared meals. Enjoy bulk discounts, catering, and wellness event opportunities to help your community thrive!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/10/NEW@.jpg",
    },
    "/mightyfit-partnerships": {
        title: "MightyFit by MightyMeals | Promote Health & Grow Your Business",
        description: "Join MightyFit by MightyMeals to connect with the health and wellness community. Share workout videos, promote your business, and inspire growth with 250+ partners!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/10/mightyfit-1.png",
    },
    "/my-account-login": {
        title: "Login to Your MightyMeals Account | Manage Healthy Meal Orders",
        description: "Access your MightyMeals account to manage orders, view the menu, and track meal deliveries. Conveniently login or register to enjoy fresh, chef-prepared meal prep services!",
        image: "https://mightymeals.com/static/media/large-loginImg.734f203eb9edbfedbbd6.png",
    },
    "/my-account-register": {
        title: "Register Your MightyMeals Account | Start Healthy Meal Delivery Today",
        description: "Create your MightyMeals account to access fresh, chef-prepared meals delivered to your door. Register now to customize orders, view the menu, and enjoy convenient meal prep services!",
        image: "https://mightymeals.com/static/media/large-loginImg.734f203eb9edbfedbbd6.png",
    },
    "/concierge": {
        title: "MightyMeals Concierge Service | Personalized Meal Ordering Made Easy",
        description: "Experience personalized service with MightyMeals Concierge. Place your meal prep orders via phone or email, get weekly check-ins, expert recommendations, and goal tracking!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/03/700x700-NEW-MM-Concierge-Service-Blog.jpg",
    },
    "/autoship/orders": {
        title: "Order Planner for MightyMeals | Customize Your Meal Delivery Schedule",
        description: "Take control of your MightyMeals orders with the Order Planner. Add, edit, or remove meals, update dietary preferences, and never miss a deadline. Simplify meal planning with customizable delivery schedules and auto-add weeks for convenience.",
        image: "https://blog.mightymeals.com/wp-content/uploads/2024/07/orderplanner-klaviyo-1024x1024.jpg",
    },
    "/rewards-program": {
        title: "MightyMeals Rewards Program | Earn Mighty Points & Save on Healthy Meals",
        description: "Join the MightyMeals Rewards Program and earn Mighty Points for every dollar spent! Redeem 100 points for $3 credit and enjoy exclusive savings on chef-prepared, healthy meals. Start saving with every order today!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2023/01/01.09.2023-Ways-to-Save-with-MightyMeals-Blog-Preview.jpg",
    },
    "/my-account/refer-a-friend": {
        title: "Refer a Friend to MightyMeals | Give 6 Free Meals & Earn $25 in Rewards",
        description: "Share the gift of healthy eating with MightyMeals! Refer your friends to receive a free box of 6 meals. When they place their first order, you’ll earn $25 worth of Mighty Points. Start sharing and saving today!",
        image: "https://blog.mightymeals.com/wp-content/uploads/2024/08/Send-a-Box-1024x683.png",
    },
};

export default metaData;